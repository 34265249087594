import {IApiTalentInterviewAvailability} from '@/shared/models/IApiTalentInterviewAvailability';
import {INTERVIEW_STATES} from '@/modules/interview/constants/INTERVIEW_STATES';

class InterviewService {
  interviewState(interview: IApiTalentInterviewAvailability): INTERVIEW_STATES {
    if(interview.completed){
      return INTERVIEW_STATES.COMPLETED;
    }
    if(interview.scheduledOn){
      return INTERVIEW_STATES.SCHEDULED;
    }
    if(interview.available){
      return INTERVIEW_STATES.AVAILABLE;
    }
    if(interview.availableOn){
      return INTERVIEW_STATES.FAILED;
    }
    return INTERVIEW_STATES.NOT_AVAILABLE
  }
}

export const INTERVIEW_SERVICE = new InterviewService();
