export enum CALENDLY_LINK_TYPE {
  DATA_ENGINEER_TECHNICAL_INTERVIEW = 'data_engineer_technical_interview',
  BLOCKCHAIN_ETH_ENGINEER_TECHNICAL_INTERVIEW = 'blockchain_eth_engineer_technical_interview',
  BACKEND_TECHNICAL_INTERVIEW = 'backend_technical_interview',
  FRONTEND_TECHNICAL_INTERVIEW = 'frontend_technical_interview',
  FLUTTER_TECHNICAL_INTERVIEW = 'flutter_technical_interview',
  DEVOPS_KUBERNETES_TECHNICAL_INTERVIEW = 'devops_kubernetes_technical_interview',
  TECHNICAL_INTERVIEW = 'technical_interview',
  FINAL_INTERVIEW = 'final_interview',
  COMPANY_CALL = 'company_call',
}
