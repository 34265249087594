/* eslint-disable vue/max-len */

import {CALENDLY_LINK_TYPE} from '@/shared/constants/CALENDLY_LINK_TYPE';
import {Icons} from '@/shared/components/icon/icons';

interface InterviewDataInterface{
  name: string;
  icon?: Icons;
  description?: string;
}

export const InterviewDataObject: Record<CALENDLY_LINK_TYPE, InterviewDataInterface> = {
  [CALENDLY_LINK_TYPE.FRONTEND_TECHNICAL_INTERVIEW]: {
    name: 'Frontend Interview',
    icon: Icons.FRONTEND,
    description: 'Frontend Technical Interview covers frontend development where you will be working on the practical assignment to showcase your skills.'
  },
  [CALENDLY_LINK_TYPE.BACKEND_TECHNICAL_INTERVIEW]: {
    name: 'Backend Interview',
    icon: Icons.BACKEND,
    description: 'Backend Technical Interview will consist of a practical assignment you will be working on to showcase your skills in your chose programming language.'
  },
  [CALENDLY_LINK_TYPE.TECHNICAL_INTERVIEW]: {
    name: 'Technical Interview',
    icon: Icons.FULLSTACK,
    description: 'Technical Interview will consist of a practical assignment you will be working on to showcase your skills'
  },
  [CALENDLY_LINK_TYPE.BLOCKCHAIN_ETH_ENGINEER_TECHNICAL_INTERVIEW]: {
    name: 'Solidity Interview',
    icon: Icons.BLOCKCHAIN,
    description: 'Blockchain Technical Interview covers foundations and practical assignment working on Ethereum blockchain where you will try to showcase your skills on blockchain.'
  },
  [CALENDLY_LINK_TYPE.DATA_ENGINEER_TECHNICAL_INTERVIEW]: {
    name: 'Data engineer Interview',
    icon: Icons.DATASCIENCE,
    description: 'This Technical Interview covers foundations and practical assignment on Data Science and Data Engineering role.'
  },
  [CALENDLY_LINK_TYPE.FLUTTER_TECHNICAL_INTERVIEW]: {
    name: 'Flutter engineer Interview',
    icon: Icons.MOBILE,
    description: 'Flutter Technical Interview will consist of a practical assignment where you will showcase your Flutter knowledge and skills.'
  },
  [CALENDLY_LINK_TYPE.DEVOPS_KUBERNETES_TECHNICAL_INTERVIEW]: {
    name: 'DevOps Kubernetes engineer Interview',
    icon: Icons.DEVOPS,
    description: 'DevOps technical interview will Showcase your DevOps skills connected to Kubernetes and beyond.'
  },
  [CALENDLY_LINK_TYPE.FINAL_INTERVIEW]: {
    name: 'Behaviour Interview',
    icon: Icons.DEVOPS,
    description: 'This Interview will check your behaviour skills'
  },
  [CALENDLY_LINK_TYPE.COMPANY_CALL]: {
    name: 'Company call',
    icon: Icons.MOBILE,
    description: 'Company has requested interview with you.'
  },
}

export function InterviewData(type: CALENDLY_LINK_TYPE): InterviewDataInterface{
  if(Object.values(CALENDLY_LINK_TYPE).includes(type as CALENDLY_LINK_TYPE)){
    return InterviewDataObject[type];
  }
  return {
    name: 'Technical interview',
    icon: Icons.FULLSTACK,
    description: 'This Technical Interview covers assess your knowledge and skills in your domain'
  }
}
