






















































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsButton from '@/shared/components/buttons/button.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Action, Getter} from 'vuex-class';
import {InterviewGetters} from '@/modules/interview/store/getters';
import {InterviewActions} from '@/modules/interview/store/actions';
import {IApiTalentInterviewAvailability} from '@/shared/models/IApiTalentInterviewAvailability';
import {CALENDLY_LINK_TYPE} from '@/shared/constants/CALENDLY_LINK_TYPE';
import InterviewModule from '@/modules/interview';
import {INTERVIEW_SERVICE} from '@/modules/interview/services/interview.service';
import {INTERVIEW_STATES} from '@/modules/interview/constants/INTERVIEW_STATES';
import {InterviewData} from '@/modules/interview/constants/interview-data';
import {InterviewRouter} from '@/modules/interview/router';
import RsCountdown from '@/shared/components/countdown/countdown.vue';
import RsLoading from '@/shared/components/loading/loading.vue';
import {Icons} from '@/shared/components/icon/icons';


@Component({
  metaInfo: {
    title: 'Schedule interview'
  },
  components: {RsLoading, RsCountdown, RsIcon, RsButton}
})
export default class InterviewIndex extends Vue {
  @Action(InterviewGetters.GET_INTERVIEWS, {namespace: InterviewModule.namespace})
  getInterviews: () => void;
  @Getter(InterviewActions.GET_INTERVIEWS, {namespace: InterviewModule.namespace})
  interviews: IApiTalentInterviewAvailability[];

  INTERVIEW_STATES = INTERVIEW_STATES;
  InterviewData = InterviewData;
  InterviewRouter = InterviewRouter;
  Icons = Icons;

  get availableInterviews(){
    return this.interviews.filter((interview) => ![
      CALENDLY_LINK_TYPE.FINAL_INTERVIEW,
      CALENDLY_LINK_TYPE.COMPANY_CALL
    ].includes(interview.type)).filter((interview) => this.state(interview) !== INTERVIEW_STATES.NOT_AVAILABLE);
  }

  state(interview: IApiTalentInterviewAvailability): INTERVIEW_STATES{
    return INTERVIEW_SERVICE.interviewState(interview)
  }

  created(){
    this.getInterviews();
  }
}
